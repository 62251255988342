import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, ListItemIcon, ListItemText } from '../../utils/mui_components';
import { HelpIcon, EmailIcon, RateReviewIcon } from '../../utils/icons';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import styled from '@emotion/styled';
var StyledToolbar = styled(Toolbar)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b['&.MuiToolbar-root'] = {
            minHeight: '50px',
        },
        _b);
});
var Header = function (_a) {
    var attributes = _a.attributes, clientLogo = _a.clientLogo, clientName = _a.clientName;
    var userEmail = get(attributes, 'email', '');
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleEmailSupport = function () {
        var mailtoLink = 'mailto:support@miracle.com';
        try {
            window.open(mailtoLink, '_blank');
            handleClose();
        }
        catch (e) {
            window.location.href = mailtoLink;
        }
    };
    var handleShareFeedback = function () {
        var feedbackLink = 'https://forms.office.com/r/Bgrvsgw924';
        try {
            window.open(feedbackLink, '_blank');
            handleClose();
        }
        catch (e) {
            window.location.href = feedbackLink;
        }
    };
    return (React.createElement(AppBar, { position: "static", color: "transparent", elevation: 0 },
        React.createElement(StyledToolbar, null,
            React.createElement(Box, { display: "flex", alignItems: "center", flexGrow: 1 },
                React.createElement(Link, { to: "/sponsor/home", style: { textDecoration: 'none' } },
                    React.createElement("img", { src: "/".concat(clientLogo), alt: "Logo", width: "70px" }))),
            React.createElement(Box, { display: "flex", alignItems: "flex-end" },
                React.createElement(Box, { marginRight: 2 },
                    React.createElement(IconButton, { onClick: handleClick, title: "Help Center" },
                        React.createElement(HelpIcon, null))),
                React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end" },
                    React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, clientName),
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, userEmail)))),
        React.createElement(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, sx: {
                '& .MuiMenu-list': {
                    padding: 0,
                },
                '& .MuiPaper-root': {
                    boxShadow: 'none',
                    border: '1px solid #ccc',
                },
            } },
            React.createElement(MenuItem, { onClick: handleEmailSupport, sx: {
                    transition: 'background-color 0.3s',
                    '&:hover': {
                        backgroundColor: 'rgba(107,33,168,.2)',
                    },
                    '&:hover .icon, &:hover .text': {
                        color: 'rgba(99,38,161,1)',
                    },
                }, disableRipple: true },
                React.createElement(ListItemIcon, { className: "icon" },
                    React.createElement(EmailIcon, null)),
                React.createElement(ListItemText, { primary: "Email Miracle Support", className: "text" })),
            React.createElement(MenuItem, { onClick: handleShareFeedback, sx: {
                    transition: 'background-color 0.3s',
                    '&:hover': {
                        backgroundColor: 'rgba(107,33,168,.2)',
                    },
                    '&:hover .icon, &:hover .text': {
                        color: 'rgba(99,38,161,1)',
                    },
                }, disableRipple: true },
                React.createElement(ListItemIcon, { className: "icon" },
                    React.createElement(RateReviewIcon, null)),
                React.createElement(ListItemText, { primary: "Share product feedback", className: "text" })))));
};
export default Header;
