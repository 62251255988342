import { toast } from 'react-toastify';
export var successToast = function (successMsg) {
    toast.success(successMsg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        style: {
            backgroundColor: '#d4edda',
            color: '#155724'
        }
    });
};
export var errorToast = function (errorMsg) {
    toast.error(errorMsg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        style: {
            backgroundColor: '#f8d7da',
            color: '#721c24'
        }
    });
};
