var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Grid, } from '../../utils/mui_components';
var VerifyOtpPage = function (_a) {
    var username = _a.username, confirmSignIn = _a.confirmSignIn, redirectUrl = _a.redirectUrl, clientLogo = _a.clientLogo;
    var _b = useState(new Array(6).fill('')), otp = _b[0], setOtp = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var handlePaste = function (event) {
        var pastedData = event.clipboardData.getData('text');
        var otpArray = pastedData.slice(0, 6).split('');
        setOtp(otpArray);
        setTimeout(function () {
            var _a;
            (_a = document.getElementById("otp-input-".concat(otpArray.length - 1))) === null || _a === void 0 ? void 0 : _a.focus();
        }, 0);
        event.preventDefault();
    };
    var handleChange = function (element, index) {
        var _a;
        setError('');
        var value = element.value;
        var newOtp = __spreadArray([], otp, true);
        newOtp[index] = value.slice(-1);
        setOtp(newOtp);
        if (value && index < otp.length - 1) {
            (_a = document.getElementById("otp-input-".concat(index + 1))) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    var handleKeyDown = function (event, index) {
        var _a;
        if (event.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                (_a = document.getElementById("otp-input-".concat(index - 1))) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }
    };
    var verifyOtp = function () { return __awaiter(void 0, void 0, void 0, function () {
        var otpValue, cognitoUser, error_1, countdown_1, countdownInterval_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setError('');
                    setLoading(true);
                    otpValue = otp.join('');
                    return [4, confirmSignIn({ challengeResponse: otpValue })];
                case 1:
                    cognitoUser = _a.sent();
                    if (cognitoUser.isSignedIn === true) {
                        setLoading(false);
                        window.location.href = redirectUrl;
                    }
                    else if (cognitoUser.isSignedIn === false) {
                        throw new Error('Invalid OTP. Please try again.');
                    }
                    return [3, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    if (error_1.message === 'Incorrect username or password.' || error_1.message === 'Invalid session for the user.') {
                        countdown_1 = 5;
                        countdownInterval_1 = setInterval(function () {
                            setError("Code expired or max attempts reached. Redirecting to signin page in ".concat(countdown_1, "..."));
                            countdown_1 -= 1;
                            if (countdown_1 === 0) {
                                clearInterval(countdownInterval_1);
                                window.location.href = '/';
                            }
                        }, 1000);
                    }
                    else {
                        setError(error_1.message);
                    }
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    return (React.createElement(Grid, { container: true, component: "main", sx: { height: '100vh' } },
        React.createElement(Grid, { item: true, display: "flex", flexDirection: "column", justifyContent: "center", xs: 12 },
            React.createElement(Container, { maxWidth: "xs" },
                React.createElement(Box, { display: "flex", justifyContent: "center", mb: 3 },
                    React.createElement("img", { src: "/".concat(clientLogo), alt: "Miracle Logo" })),
                React.createElement(Typography, { variant: "h5", align: "center", sx: { fontWeight: 700 } }, "Verify your email"),
                React.createElement(Typography, { variant: "body1", align: "center", sx: { color: 'text.secondary', mt: 1, mb: 3 } },
                    "A one-time passcode should be arriving soon to ",
                    username,
                    ". Please enter that code here."),
                React.createElement(Box, { display: "flex", justifyContent: "center", mb: 3 }, otp.map(function (data, index) { return (React.createElement(TextField, { key: index, id: "otp-input-".concat(index), sx: { textAlign: 'center', mx: 1 }, inputProps: {
                        maxLength: 1,
                        style: { textAlign: 'center' },
                    }, variant: "outlined", value: data, onChange: function (e) { return handleChange(e.target, index); }, onKeyDown: function (e) { return handleKeyDown(e, index); }, onPaste: handlePaste })); })),
                React.createElement(Button, { fullWidth: true, variant: "contained", color: "primary", onClick: verifyOtp, sx: {
                        py: 1.5,
                        backgroundColor: '#1A1A1A',
                        ':hover': { backgroundColor: '#333333' },
                    }, disabled: loading }, "Verify"),
                React.createElement(Box, { display: "flex", justifyContent: "center", mt: 2, sx: { height: '24px' } }, error && React.createElement(Typography, { variant: "body1", align: "center", sx: { color: 'red' } }, error))))));
};
export default VerifyOtpPage;
