import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, Grid, Card, CardContent, ButtonBase } from '../../utils/mui_components';
import { styled } from '@mui/material/styles';
var StyledCardContent = styled(CardContent)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b['&.MuiCardContent-root:last-child'] = {
            padding: theme.spacing(2),
        },
        _b);
});
function getDashboardById(id, DASHBOARDS) {
    for (var key in DASHBOARDS) {
        var dashboard = DASHBOARDS[key];
        if (dashboard.dashboard_id === id) {
            return { key: key, dashboard: dashboard };
        }
    }
    return undefined;
}
export function RecentDashboards(_a) {
    var recentDashboards = _a.recentDashboards, userName = _a.userName, DASHBOARDS = _a.DASHBOARDS;
    var firstName = userName.split(' ')[0];
    return recentDashboards.length === 0 ?
        (React.createElement(Box, { p: 4 },
            React.createElement(Typography, { variant: "h5", mb: 2 }, "Welcome, ".concat(firstName, "!")),
            React.createElement(Typography, { variant: "body1", sx: { color: 'text.secondary', mt: 1, mb: 3 } }, "Use the menu icon on the left to open the panel, then select a dashboard to get started.")))
        : (React.createElement(Box, { p: 4 },
            React.createElement(Typography, { variant: "h5", mb: 2 }, "Hey there, ".concat(firstName, "!")),
            React.createElement(Typography, { variant: "body1", sx: { color: 'text.secondary', mt: 1, mb: 3 } }, "Pick up where you left off"),
            React.createElement(Grid, { container: true, spacing: 2 }, recentDashboards.map(function (rd) {
                var _a;
                var _b = (_a = getDashboardById(rd.id, DASHBOARDS)) !== null && _a !== void 0 ? _a : {}, key = _b.key, dashboard = _b.dashboard;
                if (!key || !dashboard)
                    return null;
                return (React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4, key: rd.id },
                    React.createElement(ButtonBase, { component: "a", href: "/sponsor/dashboard/".concat(key, "/dashboard"), sx: { width: '100%', display: 'block', borderRadius: 2 }, disableRipple: true },
                        React.createElement(Card, { sx: {
                                boxShadow: 'none',
                                backgroundColor: 'rgba(107,33,168,.1)',
                                borderRadius: 2,
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'rgba(107,33,168,.2)',
                                },
                                '&:hover .icon, &:hover .text': {
                                    color: 'rgba(99,38,161,1)',
                                },
                                display: 'flex',
                                alignItems: 'center'
                            } },
                            React.createElement(StyledCardContent, { sx: { width: '100%' } },
                                React.createElement(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                    } },
                                    React.createElement(FontAwesomeIcon, { icon: dashboard.icon, className: "icon text-sm h-4 text-gray-700" }),
                                    React.createElement(Typography, { variant: "body1", ml: 1, className: "text" }, dashboard.fullName)))))));
            }))));
}
