import React, { useEffect } from 'react';
import { Box, CircularProgress } from '../../utils/mui_components';
var Signout = function (_a) {
    var signOut = _a.signOut;
    useEffect(function () {
        var timer = setTimeout(function () {
            signOut();
        }, 3000);
        return function () { return clearTimeout(timer); };
    }, [signOut]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", bgcolor: "rgba(255, 255, 255, 1)", zIndex: 9999, sx: { pointerEvents: 'none' } },
        React.createElement(CircularProgress, { sx: { marginRight: 1 } }),
        "Securely signing you out..."));
};
export default Signout;
