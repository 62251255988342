import React, { useState } from 'react';
import { Box, Tabs, Tab } from '../../utils/mui_components';
import UserActions from './UserActions';
import LoginActivity from './LoginActivity';
import ContentActivity from './ContentActivity';
var tabStyles = {
    color: 'rgba(0, 0, 0, 0.87)',
    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'rgba(107,33,168,.1)',
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};
export default function Audit(_a) {
    var clientName = _a.clientName, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, attributes = _a.attributes, DASHBOARDS = _a.DASHBOARDS, envName = _a.envName;
    var _b = useState(0), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider' } },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": "audit tabs", TabIndicatorProps: {
                    style: {
                        backgroundColor: 'rgba(99,38,161,1)',
                    },
                } },
                React.createElement(Tab, { label: "Login Activity", sx: tabStyles, disableRipple: true }),
                React.createElement(Tab, { label: "User Actions", sx: tabStyles, disableRipple: true }),
                React.createElement(Tab, { label: "Dashboard Activity", sx: tabStyles, disableRipple: true }))),
        React.createElement(Box, { sx: { p: 2 } },
            value === 0 && (React.createElement(LoginActivity, { clientName: clientName, envName: envName })),
            value === 1 && (React.createElement(UserActions, { clientName: clientName, envName: envName })),
            value === 2 && (React.createElement(ContentActivity, { clientName: clientName, envName: envName, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, DASHBOARDS: DASHBOARDS, attributes: attributes })))));
}
