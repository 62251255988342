var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Modal, Table, TableHead, TableBody, TableRow, TableCell, Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, List, ListItem, ListItemButton, ListItemText, CircularProgress, TextField } from '../../utils/mui_components';
import { getBackendUrl } from '../../utils/consts';
import { logApiUsage } from '../../api/audit';
var ManualUploads = function (_a) {
    var clientName = _a.clientName, envName = _a.envName, STUDY_NUMBERS = _a.STUDY_NUMBERS, attributes = _a.attributes, successToast = _a.successToast, errorToast = _a.errorToast;
    var _b = useState([]), tableData = _b[0], setTableData = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(null), selectedTable = _e[0], setSelectedTable = _e[1];
    var _f = useState(false), isUploadModalOpen = _f[0], setIsUploadModalOpen = _f[1];
    var _g = useState("existing"), schemaChoice = _g[0], setSchemaChoice = _g[1];
    var _h = useState(""), selectedExistingTable = _h[0], setSelectedExistingTable = _h[1];
    var _j = useState(null), uploadedFile = _j[0], setUploadedFile = _j[1];
    var _k = useState([]), selectedTableSchema = _k[0], setSelectedTableSchema = _k[1];
    var _l = useState(""), newTableName = _l[0], setNewTableName = _l[1];
    var _m = useState(""), selectedStudy = _m[0], setSelectedStudy = _m[1];
    var fetchTableData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var backendUrl, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    backendUrl = getBackendUrl(envName);
                    return [4, fetch("".concat(backendUrl, "/data_uploads/excel/tables?client_name=").concat(clientName), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch tables');
                    }
                    return [4, response.json()];
                case 3:
                    data = _a.sent();
                    setTableData(data);
                    return [3, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error fetching tables:', error_1);
                    setError('Failed to fetch tables. Please try again later.');
                    return [3, 6];
                case 5:
                    setLoading(false);
                    return [7];
                case 6: return [2];
            }
        });
    }); }, []);
    useEffect(function () {
        fetchTableData();
        var interval = setInterval(fetchTableData, 60000);
        return function () { return clearInterval(interval); };
    }, [fetchTableData]);
    var openModal = function (table) {
        setSelectedTable(table);
    };
    var closeModal = function () {
        setSelectedTable(null);
    };
    var openUploadModal = function () {
        setIsUploadModalOpen(true);
    };
    var closeUploadModal = function () {
        setIsUploadModalOpen(false);
        resetForm();
    };
    var resetForm = function () {
        setSchemaChoice("existing");
        setSelectedExistingTable("");
        setUploadedFile(null);
    };
    var handleFileUpload = function (event) {
        var _a;
        setUploadedFile(((_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null);
    };
    var handleFormSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, new_table_name, backendUrl, response, result, errorData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (schemaChoice === "existing" && !selectedExistingTable) {
                        alert("Please select an existing table schema!");
                        return [2];
                    }
                    if (!uploadedFile) {
                        alert("Please upload a CSV file!");
                        return [2];
                    }
                    formData = new FormData();
                    formData.append("schema_choice", schemaChoice);
                    if (schemaChoice === "existing") {
                        formData.append("selected_table", selectedExistingTable);
                    }
                    new_table_name = "";
                    if (schemaChoice === "new") {
                        new_table_name = selectedStudy + '__' + newTableName.replace(/\s+/g, '_').toLowerCase();
                        formData.append("new_table_name", new_table_name);
                    }
                    formData.append("file", uploadedFile);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 8, 9, 10]);
                    backendUrl = getBackendUrl(envName);
                    return [4, fetch("".concat(backendUrl, "/data_uploads/upload_csv?client_name=").concat(clientName), {
                            method: "POST",
                            body: formData,
                        })];
                case 2:
                    response = _a.sent();
                    return [4, logApiUsage(clientName, attributes.email, '/data_uploads/upload_csv', "User uploaded csv file to ".concat(schemaChoice === "existing" ? selectedExistingTable : new_table_name))];
                case 3:
                    _a.sent();
                    if (!response.ok) return [3, 5];
                    return [4, response.json()];
                case 4:
                    result = _a.sent();
                    successToast(result.message);
                    return [3, 7];
                case 5: return [4, response.json()];
                case 6:
                    errorData = _a.sent();
                    errorToast(errorData.detail);
                    _a.label = 7;
                case 7: return [3, 10];
                case 8:
                    error_2 = _a.sent();
                    console.error("Error submitting form:", error_2);
                    errorToast("Failed to upload csv");
                    return [3, 10];
                case 9:
                    setSelectedExistingTable("");
                    setSelectedStudy("");
                    setNewTableName("");
                    return [7];
                case 10:
                    closeUploadModal();
                    return [2];
            }
        });
    }); };
    var fetchTableSchema = function (tableName) { return __awaiter(void 0, void 0, void 0, function () {
        var backendUrl, response, schemaData, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    backendUrl = getBackendUrl(envName);
                    return [4, fetch("".concat(backendUrl, "/data_uploads/excel/table_schema?client_name=").concat(clientName, "&table_name=").concat(tableName), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch table schema');
                    }
                    return [4, response.json()];
                case 2:
                    schemaData = _a.sent();
                    setSelectedTableSchema(schemaData);
                    return [3, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error('Error fetching table schema:', error_3);
                    setError('Failed to fetch table schema. Please try again later.');
                    return [3, 4];
                case 4: return [2];
            }
        });
    }); };
    useEffect(function () {
        if (selectedExistingTable) {
            fetchTableSchema(selectedExistingTable);
        }
    }, [selectedExistingTable]);
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", sx: { marginTop: "20px", padding: "10px 15px", cursor: "pointer" }, onClick: openUploadModal }, "Upload CSV"),
        React.createElement(Modal, { open: isUploadModalOpen, onClose: closeUploadModal, sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            } },
            React.createElement(Box, { sx: { bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 3, height: "90%", width: "80%", overflowY: "scroll" } },
                React.createElement(Typography, { variant: "h6", gutterBottom: true }, "Upload CSV File"),
                React.createElement("form", { onSubmit: handleFormSubmit },
                    React.createElement(FormControl, { component: "fieldset" },
                        React.createElement(RadioGroup, { row: true, name: "schemaChoice", value: schemaChoice, onChange: function (e) { return setSchemaChoice(e.target.value); } },
                            React.createElement(FormControlLabel, { value: "existing", control: React.createElement(Radio, null), label: "Update Existing Table" }),
                            React.createElement(FormControlLabel, { value: "new", control: React.createElement(Radio, null), label: "Create New Table" }))),
                    schemaChoice === "existing" && (React.createElement(Box, { sx: { mt: 2 } },
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(FormLabel, null, "Select Table"),
                            React.createElement(Select, { value: selectedExistingTable, onChange: function (e) { return setSelectedExistingTable(e.target.value); }, required: true },
                                React.createElement(MenuItem, { value: "" },
                                    React.createElement("em", null, "-- Select a table --")),
                                tableData.map(function (table, index) { return (React.createElement(MenuItem, { key: index, value: table.table_name }, table.table_name)); }))),
                        selectedExistingTable && selectedTableSchema.length > 0 && (React.createElement(Box, { sx: { mt: 2 } },
                            React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Please ensure that the column names and types in your csv file match the existing table schema below."),
                            React.createElement(Table, null,
                                React.createElement(TableHead, { sx: { bgcolor: 'rgba(0, 0, 0, 0.08)' } },
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null, "Column Name"),
                                        React.createElement(TableCell, null, "Column Type"))),
                                React.createElement(TableBody, null, selectedTableSchema.map(function (column, index) {
                                    if (column.column_name !== 'MIRACLE_RUN_ID') {
                                        return (React.createElement(TableRow, { key: index },
                                            React.createElement(TableCell, null, column.column_name),
                                            React.createElement(TableCell, null, column.data_type)));
                                    }
                                }))))))),
                    schemaChoice === "new" && (React.createElement(Box, { sx: { mt: 2 } },
                        React.createElement(FormControl, { fullWidth: true, sx: { mb: 2 } },
                            React.createElement(FormLabel, null, "Select Study"),
                            React.createElement(Select, { value: selectedStudy, onChange: function (e) { return setSelectedStudy(e.target.value); }, required: true },
                                React.createElement(MenuItem, { value: "" },
                                    React.createElement("em", null, "-- Select a study --")),
                                STUDY_NUMBERS.map(function (study, index) { return (React.createElement(MenuItem, { key: index, value: study }, study)); }))),
                        React.createElement(TextField, { fullWidth: true, label: "Table Name", value: newTableName, onChange: function (e) { return setNewTableName(e.target.value); }, required: true }))),
                    React.createElement(Box, { sx: { mt: 2 } },
                        React.createElement(FormLabel, null, "Upload CSV File"),
                        React.createElement("input", { type: "file", accept: ".csv", onChange: handleFileUpload, required: true, style: { marginLeft: "10px" } })),
                    React.createElement(Box, { sx: { mt: 2, display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary" }, "Submit"),
                        React.createElement(Button, { type: "button", onClick: closeUploadModal, variant: "outlined", color: "secondary" }, "Cancel"))))),
        loading ? (React.createElement("p", null,
            React.createElement(CircularProgress, null))) : (React.createElement(List, null,
            React.createElement(ListItem, { sx: { fontWeight: 'bold', bgcolor: 'rgba(0, 0, 0, 0.08)' } },
                React.createElement(ListItemText, { primary: "Table Name", sx: { flex: '1 1 50%' } }),
                React.createElement(ListItemText, { primary: "Last Updated At", sx: { flex: '1 1 50%' } })),
            tableData.map(function (table, index) { return (React.createElement(ListItem, { key: index, disablePadding: true },
                React.createElement(ListItemButton, { onClick: function () { return openModal(table); }, sx: {
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        },
                        display: 'flex'
                    } },
                    React.createElement(ListItemText, { primary: table.table_name, sx: { flex: '1 1 50%' } }),
                    React.createElement(ListItemText, { primary: new Date(Math.max.apply(Math, table.data.map(function (row) { return new Date(row.MIRACLE_RUN_ID).getTime(); }))).toLocaleString(), sx: { flex: '1 1 50%' } })))); }))),
        React.createElement(Modal, { open: !!selectedTable, onClose: closeModal, sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            } },
            React.createElement(Box, { sx: { width: "80%", margin: "auto", height: "90%", borderRadius: "10px", bgcolor: "background.paper", p: 4, overflow: "auto" } }, selectedTable && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "h6", component: "h2", gutterBottom: true }, selectedTable.table_name),
                React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, Object.keys(selectedTable.data[0] || {}).map(function (header) { return (React.createElement(TableCell, { key: header }, header)); }))),
                    React.createElement(TableBody, null, selectedTable.data.map(function (row, rowIndex) { return (React.createElement(TableRow, { key: rowIndex }, Object.values(row).map(function (value, cellIndex) { return (React.createElement(TableCell, { key: cellIndex }, value)); }))); }))),
                React.createElement(Button, { onClick: closeModal, variant: "contained", sx: { mt: 2 } }, "Close")))))));
};
export default ManualUploads;
