(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("react-router-dom"), require("react-toastify"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "react-router-dom", "react-toastify"], factory);
	else if(typeof exports === 'object')
		exports["commonComponents"] = factory(require("react"), require("react-dom"), require("react-router-dom"), require("react-toastify"));
	else
		root["commonComponents"] = factory(root["react"], root["react-dom"], root["react-router-dom"], root["react-toastify"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__9514__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__8673__) => {
return 