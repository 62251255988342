import React from 'react';
import Dashboard from '../dashboard/Dashboard';
import { Box, Paper } from '../../utils/mui_components';
var ContentActivity = function (_a) {
    var SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, attributes = _a.attributes, clientName = _a.clientName, DASHBOARDS = _a.DASHBOARDS, envName = _a.envName;
    return (React.createElement(Box, null,
        React.createElement(Paper, { elevation: 3, sx: { padding: 2, backgroundColor: '#f5f5f5' } },
            React.createElement(Box, { sx: { height: 'calc(100vh - 250px)', paddding: 0, borderRadius: 2, overflow: 'hidden' } },
                React.createElement(Dashboard, { dashboardId: SPECIAL_DASHBOARDS.content_activity.dashboard_id, cardId: null, tabId: null, attributes: attributes, clientName: clientName, envName: envName, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS })))));
};
export default ContentActivity;
