var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, TextField, Button, Select, MenuItem, Box, Chip, TablePagination, TableSortLabel, IconButton, Tooltip, Modal, } from '../../utils/mui_components';
import debounce from 'lodash/debounce';
import { RefreshIcon, FileDownloadIcon, SearchIcon } from '../../utils/icons';
import { getBackendUrl } from '../../utils/consts';
var eventTypes = ['VIEW_DASHBOARD', 'ADD_USER', 'UPDATE_USER', 'DELETE_USER'];
function UserActions(_a) {
    var _this = this;
    var clientName = _a.clientName, envName = _a.envName;
    var _b = useState([]), logs = _b[0], setLogs = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(''), eventType = _e[0], setEventType = _e[1];
    var _f = useState(''), selectedDate = _f[0], setSelectedDate = _f[1];
    var _g = useState(0), page = _g[0], setPage = _g[1];
    var _h = useState(15), rowsPerPage = _h[0], setRowsPerPage = _h[1];
    var _j = useState('event_timestamp'), sortBy = _j[0], setSortBy = _j[1];
    var _k = useState('desc'), sortDirection = _k[0], setSortDirection = _k[1];
    var _l = useState(''), searchTerm = _l[0], setSearchTerm = _l[1];
    var _m = useState(false), openModal = _m[0], setOpenModal = _m[1];
    var _o = useState(null), selectedLog = _o[0], setSelectedLog = _o[1];
    var fetchLogs = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var backendUrl, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    backendUrl = getBackendUrl(envName);
                    return [4, fetch("".concat(backendUrl, "/audit/api/user_actions?client_name=").concat(clientName), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch logs');
                    }
                    return [4, response.json()];
                case 3:
                    data = _a.sent();
                    setLogs(data);
                    return [3, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error fetching logs:', error_1);
                    setError('Failed to fetch logs. Please try again later.');
                    return [3, 6];
                case 5:
                    setLoading(false);
                    return [7];
                case 6: return [2];
            }
        });
    }); }, []);
    useEffect(function () {
        fetchLogs();
        var interval = setInterval(fetchLogs, 60000);
        return function () { return clearInterval(interval); };
    }, [fetchLogs]);
    var debouncedFilter = useCallback(debounce(function (logs) {
        var filtered = logs.filter(function (log) {
            var matchesEventType = eventType ? log.event_type === eventType : true;
            var matchesDate = selectedDate
                ? new Date(log.event_timestamp).toDateString() === new Date(selectedDate).toDateString()
                : true;
            var matchesSearch = searchTerm
                ? Object.values(log).some(function (value) {
                    return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }) || JSON.stringify(log.details).toLowerCase().includes(searchTerm.toLowerCase())
                : true;
            return matchesEventType && matchesDate && matchesSearch;
        });
        filtered.sort(function (a, b) {
            if (a[sortBy] < b[sortBy])
                return sortDirection === 'asc' ? -1 : 1;
            if (a[sortBy] > b[sortBy])
                return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredLogs(filtered);
    }, 300), [eventType, selectedDate, sortBy, sortDirection, searchTerm]);
    var _p = useState([]), filteredLogs = _p[0], setFilteredLogs = _p[1];
    useEffect(function () {
        debouncedFilter(logs);
    }, [logs, debouncedFilter]);
    var clearFilters = function () {
        setEventType('');
        setSelectedDate('');
        setSearchTerm('');
    };
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    var handleSort = function (property) {
        var isAsc = sortBy === property && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };
    var handleExportCSV = function () {
        var csvContent = __spreadArray([
            ['Event Type', 'Timestamp', 'User Email', 'Additional Info']
        ], filteredLogs.map(function (log) { return [
            log.event_type,
            log.event_timestamp,
            log.user_id,
            log.details
        ]; }), true).map(function (row) { return row.join(','); }).join('\n');
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'user_actions.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    var handleSearch = function (event) {
        setSearchTerm(event.target.value);
    };
    var handleRowClick = function (log) {
        setSelectedLog(log);
        setOpenModal(true);
    };
    var handleCloseModal = function () {
        setOpenModal(false);
        setSelectedLog(null);
    };
    return (React.createElement(Box, { sx: { padding: '20px' } },
        React.createElement(Box, { sx: { marginBottom: '20px', display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' } },
            React.createElement(TextField, { label: "Search", value: searchTerm, onChange: handleSearch, size: "small", InputProps: {
                    startAdornment: React.createElement(SearchIcon, null),
                } }),
            React.createElement(Select, { value: eventType, onChange: function (e) { return setEventType(e.target.value); }, displayEmpty: true, size: "small", sx: { minWidth: 200 } },
                React.createElement(MenuItem, { value: "" }, "All Event Types"),
                eventTypes.map(function (type) { return (React.createElement(MenuItem, { key: type, value: type }, type)); })),
            React.createElement(TextField, { label: "Date", type: "date", value: selectedDate, onChange: function (e) { return setSelectedDate(e.target.value); }, InputLabelProps: { shrink: true }, size: "small" }),
            React.createElement(Button, { variant: "outlined", color: "secondary", onClick: clearFilters, size: "small" }, "Clear Filters"),
            React.createElement(Tooltip, { title: "Refresh logs" },
                React.createElement(IconButton, { onClick: fetchLogs, size: "small" },
                    React.createElement(RefreshIcon, null))),
            React.createElement(Tooltip, { title: "Export to CSV" },
                React.createElement(IconButton, { onClick: handleExportCSV, size: "small" },
                    React.createElement(FileDownloadIcon, null)))),
        error && (React.createElement(Typography, { color: "error", sx: { marginBottom: '20px' } }, error)),
        loading ? (React.createElement(CircularProgress, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "subtitle1", gutterBottom: true },
                "Showing ",
                filteredLogs.length,
                " of ",
                logs.length,
                " logs"),
            React.createElement(TableContainer, { component: Paper },
                React.createElement(Table, { size: "small" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, ['event_timestamp', 'user_id', 'event_type', 'details'].map(function (column) { return (React.createElement(TableCell, { key: column },
                            React.createElement(TableSortLabel, { active: sortBy === column, direction: sortBy === column ? sortDirection : 'asc', onClick: function () { return handleSort(column); } }, column.replace('_', ' ').toUpperCase()))); }))),
                    React.createElement(TableBody, null, filteredLogs
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(function (log) { return (React.createElement(TableRow, { key: log.id, onClick: function () { return handleRowClick(log); }, sx: { cursor: 'pointer' } }, ['event_timestamp', 'user_id', 'event_type', 'details'].map(function (column) { return (React.createElement(TableCell, { key: column }, column === 'details' ? (typeof log.details === 'object' ? (Object.entries(log.details).map(function (_a) {
                        var key = _a[0], value = _a[1];
                        return (React.createElement(Chip, { key: key, label: "".concat(key, ": ").concat(value), size: "small", sx: { margin: '2px' } }));
                    })) : (log.details)) : (log[column]))); }))); })))),
            React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 15, 25], component: "div", count: filteredLogs.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))),
        React.createElement(Modal, { open: openModal, onClose: handleCloseModal },
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                } },
                React.createElement(Typography, { variant: "h6", component: "h2" }, "Log Details"),
                selectedLog && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, null,
                        "Event Type: ",
                        selectedLog.event_type),
                    React.createElement(Typography, null,
                        "Event Timestamp: ",
                        new Date(selectedLog.event_timestamp).toLocaleString()),
                    React.createElement(Typography, null,
                        "User Email: ",
                        selectedLog.user_id),
                    React.createElement(Typography, null,
                        "Details: ",
                        typeof selectedLog.details === 'object' ? JSON.stringify(selectedLog.details) : selectedLog.details)))))));
}
export default UserActions;
