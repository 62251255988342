var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { List, ListItem, ListItemIcon, ListItemText, Box, Collapse, ListItemButton, IconButton } from '../../utils/mui_components';
import { SearchIcon, HomeIcon, FolderIcon, FolderOpenIcon, ExpandMoreIcon, SettingsIcon, LogoutIcon, FontAwesomeIcon, BarChartIcon, StarIcon } from '../../utils/icons';
import SearchBar from './SearchBar';
import { getBackendUrl } from '../../utils/consts';
import { errorToast } from '../../utils/helperFns';
var listItemStyles = {
    height: '50px',
    padding: '4px 16px',
    '&.Mui-selected': {
        backgroundColor: 'rgba(107,33,168,.1)',
        '&:hover': {
            backgroundColor: 'rgba(107,33,168,.2)',
        },
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};
var listItemTextStyles = {
    '& .MuiListItemText-primary': {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '& .MuiListItemText-secondary': {
        fontSize: '12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
};
var starIconStyles = {
    notFavorited: {
        '& svg': {
            fill: 'transparent',
            stroke: 'rgba(0, 0, 0, 0.54)',
        },
        '&:hover svg': {
            fill: 'rgba(0, 0, 0, 0.2)',
        },
        '&:active svg': {
            fill: 'rgba(0, 0, 0, 0.54)',
        },
    },
    favorited: {
        '& svg': {
            fill: 'rgba(107,33,168,.2)',
            stroke: '#7C3AED',
        },
        '&:hover svg': {
            fill: 'rgba(107,33,168,.5)',
        },
        '&:active svg': {
            fill: 'rgba(107,33,168,1)',
        },
    },
};
var CollapsibleSidebar = function (_a) {
    var clientName = _a.clientName, envName = _a.envName, open = _a.open, setOpen = _a.setOpen, attributes = _a.attributes, navigate = _a.navigate, location = _a.location, STUDIES = _a.STUDIES, DASHBOARDS = _a.DASHBOARDS, getAccessibleDashboards = _a.getAccessibleDashboards, fetchAuthSession = _a.fetchAuthSession;
    var _b = useState(false), searchBarOpen = _b[0], setSearchBarOpen = _b[1];
    var _c = useState({}), subMenuItemsOpen = _c[0], setSubMenuItemsOpen = _c[1];
    var _d = useState(''), selectedPage = _d[0], setSelectedPage = _d[1];
    var _e = useState(false), textVisible = _e[0], setTextVisible = _e[1];
    var _f = useState({}), favorites = _f[0], setFavorites = _f[1];
    useEffect(function () {
        if (open) {
            var timer_1 = setTimeout(function () { return setTextVisible(true); }, 300);
            return function () { return clearTimeout(timer_1); };
        }
        else {
            setTextVisible(false);
        }
    }, [open]);
    var accessibleDashboardKeys = useMemo(function () { return getAccessibleDashboards(get(attributes, 'role', '')); }, [attributes]);
    var accessibleDashboards = useMemo(function () { return Object.fromEntries(Object.entries(DASHBOARDS).filter(function (_a) {
        var key = _a[0], data = _a[1];
        return accessibleDashboardKeys.includes(key);
    })); }, [accessibleDashboardKeys]);
    useEffect(function () {
        var _a;
        var _b;
        var pathParts = location.pathname.split('/');
        if (pathParts.includes('dashboard')) {
            var currentDashboardKey = pathParts[3];
            if (selectedPage !== currentDashboardKey) {
                setSelectedPage(currentDashboardKey);
            }
            var currentStudy = (_b = accessibleDashboards[currentDashboardKey]) === null || _b === void 0 ? void 0 : _b.study;
            if (currentStudy) {
                setSubMenuItemsOpen((_a = {}, _a[currentStudy] = true, _a));
            }
        }
        else if (location.pathname === '/sponsor/home' && selectedPage !== 'home') {
            setSelectedPage('home');
            setSubMenuItemsOpen({});
        }
        else if (location.pathname === '/sponsor/settings' && selectedPage !== 'settings') {
            setSelectedPage('settings');
            setSubMenuItemsOpen({});
        }
        else if (selectedPage !== '') {
            setSelectedPage('');
            setSubMenuItemsOpen({});
        }
    }, [location.pathname]);
    useEffect(function () {
        var fetchFavorites = function () { return __awaiter(void 0, void 0, void 0, function () {
            var backendUrl, response, result, favoriteDashboards, favorites_1, errorData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        backendUrl = getBackendUrl(envName);
                        return [4, fetch("".concat(backendUrl, "/user/favorites?client_name=").concat(clientName, "&user_email=").concat(get(attributes, 'email', '')))];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) return [3, 3];
                        return [4, response.json()];
                    case 2:
                        result = _a.sent();
                        favoriteDashboards = result.favorite_dashboards;
                        try {
                            favorites_1 = favoriteDashboards.reduce(function (acc, favoriteKey) {
                                acc[favoriteKey] = DASHBOARDS[favoriteKey];
                                return acc;
                            }, {});
                            setFavorites(favorites_1);
                        }
                        catch (error) {
                            console.error("Error fetching favorites:", error);
                        }
                        return [3, 5];
                    case 3: return [4, response.json()];
                    case 4:
                        errorData = _a.sent();
                        errorToast(errorData.detail);
                        _a.label = 5;
                    case 5: return [2];
                }
            });
        }); };
        fetchFavorites();
    }, []);
    var toggleSubMenu = function (study) {
        setSubMenuItemsOpen(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[study] = !prev[study], _a)));
        });
    };
    var handleNavigation = function (path, pageKey, study) {
        var _a;
        if (pageKey) {
            setSelectedPage(pageKey);
            setSubMenuItemsOpen((_a = {}, _a[study] = true, _a));
        }
        navigate(path);
    };
    var handleFavorite = function (key) { return __awaiter(void 0, void 0, void 0, function () {
        var isNewFavorite, payload, backendUrl, response, newFavorites, newFavorites, errorData, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    isNewFavorite = !favorites[key];
                    payload = {
                        user_email: get(attributes, 'email', ''),
                        action: isNewFavorite ? 'add' : 'remove',
                        dashboard_key: key,
                    };
                    backendUrl = getBackendUrl(envName);
                    return [4, fetch("".concat(backendUrl, "/user/favorites?client_name=").concat(clientName), {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            method: "POST",
                            body: JSON.stringify(payload),
                        })];
                case 1:
                    response = _b.sent();
                    if (!response.ok) return [3, 2];
                    if (isNewFavorite) {
                        newFavorites = __assign(__assign({}, favorites), (_a = {}, _a[key] = DASHBOARDS[key], _a));
                        setFavorites(newFavorites);
                    }
                    else {
                        newFavorites = __assign({}, favorites);
                        delete newFavorites[key];
                        setFavorites(newFavorites);
                    }
                    return [3, 4];
                case 2: return [4, response.json()];
                case 3:
                    errorData = _b.sent();
                    errorToast(errorData.detail);
                    _b.label = 4;
                case 4: return [3, 6];
                case 5:
                    error_1 = _b.sent();
                    console.error("Error adding favorite:", error_1);
                    return [3, 6];
                case 6: return [2];
            }
        });
    }); };
    var handleSignout = function () {
        navigate('/signout');
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" },
        React.createElement(List, { disablePadding: true },
            React.createElement(ListItemButton, { onClick: function () { return setSearchBarOpen(!searchBarOpen); }, sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                        React.createElement(SearchIcon, { fontSize: "small" })),
                    textVisible && React.createElement(ListItemText, { sx: listItemTextStyles, primary: "Search" }),
                    searchBarOpen && React.createElement(SearchBar, { attributes: attributes, clientName: clientName, envName: envName, open: searchBarOpen, setOpen: setSearchBarOpen, DASHBOARDS: DASHBOARDS, fetchAuthSession: fetchAuthSession }))),
            React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/home', 'home'); }, selected: selectedPage === 'home', sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                        React.createElement(HomeIcon, { fontSize: "small" })),
                    textVisible && React.createElement(ListItemText, { sx: listItemTextStyles, primary: "Home" }))),
            React.createElement(ListItemButton, { onClick: function () { return toggleSubMenu('favorites'); }, sx: listItemStyles, disableRipple: true },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                        React.createElement(StarIcon, { fontSize: "small" })),
                    textVisible && (React.createElement(React.Fragment, null,
                        React.createElement(ListItemText, { sx: listItemTextStyles, primary: "Favorites" }),
                        React.createElement(ExpandMoreIcon, { fontSize: "small" }))))),
            React.createElement(Collapse, { in: subMenuItemsOpen['favorites'], timeout: "auto", unmountOnExit: true },
                React.createElement(List, { component: "div", disablePadding: true }, Object.entries(favorites).map(function (_a) {
                    var key = _a[0], favorite = _a[1];
                    return (React.createElement(ListItemButton, { key: favorite.dashboard_id, onClick: function () { return handleNavigation("/sponsor/dashboard/".concat(key, "/dashboard")); }, sx: listItemStyles, disableRipple: true },
                        React.createElement(ListItem, { disablePadding: true },
                            React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                                React.createElement(FontAwesomeIcon, { icon: favorite.icon })),
                            textVisible &&
                                React.createElement(React.Fragment, null,
                                    React.createElement(ListItemText, { sx: listItemTextStyles, primary: favorite.name, secondary: favorite.study }),
                                    React.createElement(IconButton, { onClick: function (e) {
                                            e.stopPropagation();
                                            handleFavorite(key);
                                        }, size: "small", sx: __assign(__assign({}, starIconStyles.favorited), { marginLeft: '8px', padding: 0 }) },
                                        React.createElement(StarIcon, { fontSize: "small" }))))));
                }))),
            STUDIES.map(function (study) { return (React.createElement(React.Fragment, { key: study },
                React.createElement(ListItemButton, { onClick: function () { return toggleSubMenu(study); }, sx: listItemStyles, disableRipple: true },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, { sx: { minWidth: '36px' } }, subMenuItemsOpen[study] ? React.createElement(FolderOpenIcon, { fontSize: "small" }) : React.createElement(FolderIcon, { fontSize: "small" })),
                        textVisible && (React.createElement(React.Fragment, null,
                            React.createElement(ListItemText, { sx: listItemTextStyles, primary: study }),
                            React.createElement(ExpandMoreIcon, { fontSize: "small" }))))),
                React.createElement(Collapse, { in: subMenuItemsOpen[study], timeout: "auto", unmountOnExit: true },
                    React.createElement(List, { component: "div", disablePadding: true }, Object.entries(accessibleDashboards)
                        .filter(function (_a) {
                        var key = _a[0], data = _a[1];
                        return data.study === study;
                    })
                        .map(function (_a) {
                        var key = _a[0], data = _a[1];
                        return (React.createElement(ListItemButton, { key: key, sx: __assign(__assign({}, listItemStyles), { display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 1 }), disableRipple: true, selected: selectedPage === key, onClick: function () { return handleNavigation("/sponsor/dashboard/".concat(key, "/dashboard"), key, study); } },
                            React.createElement(ListItemIcon, { sx: { minWidth: '32px' } },
                                React.createElement(FontAwesomeIcon, { icon: data.icon })),
                            React.createElement(ListItemText, { primary: data.name, sx: listItemTextStyles }),
                            open && (React.createElement(IconButton, { onClick: function (e) {
                                    e.stopPropagation();
                                    handleFavorite(key);
                                }, size: "small", sx: __assign({ padding: '4px', marginLeft: 'auto', width: '28px', height: '28px' }, (favorites[key] ? starIconStyles.favorited : starIconStyles.notFavorited)) },
                                React.createElement(StarIcon, { fontSize: "small" })))));
                    }))))); })),
        React.createElement(List, { sx: { marginTop: 'auto' }, disablePadding: true },
            get(attributes, 'role', '') === 'admin' && (React.createElement(React.Fragment, null,
                React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/user_metrics', 'user_metrics'); }, selected: selectedPage === 'user_metrics', sx: listItemStyles },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                            React.createElement(BarChartIcon, null)),
                        textVisible && React.createElement(ListItemText, { sx: listItemTextStyles, primary: "User Metrics" }))),
                React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/settings', 'settings'); }, selected: selectedPage === 'settings', sx: listItemStyles },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                            React.createElement(SettingsIcon, null)),
                        textVisible && React.createElement(ListItemText, { sx: listItemTextStyles, primary: "Settings" }))))),
            React.createElement(ListItemButton, { onClick: handleSignout, sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, { sx: { minWidth: '36px' } },
                        React.createElement(LogoutIcon, null)),
                    textVisible && React.createElement(ListItemText, { sx: listItemTextStyles, primary: "Sign out" }))))));
};
export default CollapsibleSidebar;
