import React, { useState } from 'react';
import { Box, Tabs, Tab } from '../../utils/mui_components';
import DataIntegrations from './DataIntegrations';
import MetabaseProviderWrapper from '../layout/MetabaseProvider';
import ManualUploads from './ManualUploads';
var tabStyles = {
    color: 'rgba(0, 0, 0, 0.87)',
    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'rgba(107,33,168,.1)',
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};
export default function Data(_a) {
    var clientName = _a.clientName, INTEGRATIONS_QUESTIONS = _a.INTEGRATIONS_QUESTIONS, INTEGRATIONS_DASHBOARDS = _a.INTEGRATIONS_DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, attributes = _a.attributes, DASHBOARDS = _a.DASHBOARDS, STUDY_NUMBERS = _a.STUDY_NUMBERS, envName = _a.envName, successToast = _a.successToast, errorToast = _a.errorToast;
    var _b = useState(0), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider' } },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": "data tabs", TabIndicatorProps: {
                    style: {
                        backgroundColor: 'rgba(99,38,161,1)',
                    },
                } },
                React.createElement(Tab, { label: "Integrations", sx: tabStyles, disableRipple: true }),
                React.createElement(Tab, { label: "Manual Uploads", sx: tabStyles, disableRipple: true }))),
        React.createElement(Box, { sx: { p: 2 } },
            value === 0 && (React.createElement(MetabaseProviderWrapper, { attributes: attributes, envName: envName },
                React.createElement(DataIntegrations, { INTEGRATIONS_QUESTIONS: INTEGRATIONS_QUESTIONS, INTEGRATIONS_DASHBOARDS: INTEGRATIONS_DASHBOARDS, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, attributes: attributes, clientName: clientName, envName: envName }))),
            value === 1 && (React.createElement(ManualUploads, { clientName: clientName, envName: envName, STUDY_NUMBERS: STUDY_NUMBERS, attributes: attributes, successToast: successToast, errorToast: errorToast })))));
}
